export const RepairSvg = () => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <svg
        width="36"
        height="51"
        viewBox="0 0 36 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5161 46.7742H3.48386L15.8065 0H20.1935L32.5161 46.7742Z"
          fill="white"
        />
        <path
          d="M5.60484 38.7097L7.73389 30.6452H28.2661L30.3952 38.7097H5.60484Z"
          fill="#4FD3FC"
        />
        <path
          d="M9.64517 23.3871L11.7742 15.3226H24.2339L26.3629 23.3871H9.64517Z"
          fill="#4FD3FC"
        />
        <path
          d="M13.6855 8.06452L15.8145 0H20.2016L22.3306 8.06452H13.6855Z"
          fill="#4FD3FC"
        />
        <path
          d="M35.7419 45.1613H0.258057V50H35.7419V45.1613Z"
          fill="#4FD3FC"
        />
        <path
          d="M34.9355 45.9677H1.06452V49.1935H34.9355V45.9677Z"
          stroke="#1A202C"
          strokeWidth="2"
        />
        <path
          d="M30.4193 45.1613L18.9516 1.6129H17.0484L5.58064 45.1613H30.4193ZM32.5161 46.7742H3.48386L15.8064 0H20.1935L32.5161 46.7742Z"
          fill="#1A202C"
        />
      </svg>
    </div>
  );
};
