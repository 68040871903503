import { observer } from "mobx-react";
import { Body1, Body2, Subtitle1, contents } from "ms-ui";
import { RepairSvg } from "src/components/svg/RepairSvg";
import { useStores } from "src/store/global";
import styled from "styled-components";

const RepairPage = () => {
  const { repairStore } = useStores();

  return (
    <RepairBlock>
      <RepairSvg />
      <StTitle>지금은 서버 점검 및 업데이트 중입니다.</StTitle>
      {repairStore.isRepair && (
        <StContent>
          <div className="title">{`< 서버 점검 일정 >`}</div>
          {repairStore.schedule?.start && (
            <div className="from">{`${repairStore.schedule.start} 부터`}</div>
          )}
          {repairStore.schedule?.end && (
            <div className="to">{`${repairStore.schedule.end} 까지`}</div>
          )}
        </StContent>
      )}
    </RepairBlock>
  );
};

export default observer(RepairPage);

const RepairBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${contents.secondary.value};
`;

const StTitle = styled.div`
  ${Subtitle1}
  width: 100%;
  text-align: center;
  color: ${contents.primary.value};
  margin-bottom: 8px;
`;
const StContent = styled.div`
  ${Body1}
  color: ${contents.subtitle.value};
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .title {
    width: 100%;
    text-align: center;
  }

  .from {
    width: 100%;
    text-align: center;
  }

  .to {
    width: 100%;
    text-align: center;
  }
`;

const StComment = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Body2}
  color: ${contents.accent.value};
  white-space: pre-line;
  line-height: 12px;
  text-align: center;
`;
